import React from 'react';
import Search from '@bahaide/shared/components/Search';

import {
    TwoColumns,
    Sidebar,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../components/InnerPage';

const SearchPage = () => {
    let queryString;
    try {
        const urlParams = new URLSearchParams(window.location.search);
        queryString = urlParams.get('q');
    } catch (oError) {
        console.log(oError);
    }
    return (
        <InnerPage title="Bahai.de Suche" description="">
            <TwoColumns>
                <Content>
                    <div className="title">
                        <h1>
                            Suchergebnisse: <span>{queryString}</span>
                        </h1>
                    </div>
                    <Search query={queryString} />
                </Content>
                <Sidebar />
            </TwoColumns>
        </InnerPage>
    );
};

export default SearchPage;
