import React, { Fragment } from 'react';
import Axios from 'axios';

const CSE_API_KEY = 'AIzaSyC53YW-sHDLanJ9FiSMP23zC34rdOXlb9g';

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search: [],
            searchResults: [],
            isLoading: true,
            isError: false,
            startIndex: 1,
            count: 10,
            searchQuery: props.query,
            nextPage: false,
            prevPage: false,
        };
    }

    componentDidMount() {
        this._getSearchResults();
    }

    _scrollToTop() {
        try {
            window.scrollTo(0, 0);
        } catch (oError) {
            console.log(oError);
        }
    }

    _nextPage(e) {
        e.preventDefault();
        this.setState(
            {
                startIndex: this.state.startIndex + 10,
            },
            () => this._getSearchResults()
        );
    }

    _prevPage(e) {
        e.preventDefault();
        this.setState(
            {
                startIndex: this.state.startIndex - 10,
            },
            () => this._getSearchResults()
        );
    }

    _getSearchResults() {
        Axios.get(
            `https://www.googleapis.com/customsearch/v1?key=${CSE_API_KEY}&cx=007321109176464848810:n-ap2niofg8&q=${this.state.searchQuery}&safe=active&num=${this.state.count}&start=${this.state.startIndex}`
        )
            .then(result => {
                this.setState({
                    searchResults: result.data.items,
                    isLoading: false,
                    nextPage: result.data.queries.nextPage && true,
                    prevPage: result.data.queries.previousPage && true,
                });
                this._scrollToTop();
            })
            .catch(err => {
                this.setState({ isError: true });
                console.log('====================================');
                console.log(
                    `Something bad happened while fetching the data\n${err}`
                );
                console.log('====================================');
            });
    }

    render() {
        return (
            <Fragment>
                {this.state.isLoading && 'Suchergebnisse werden geladen...'}
                {this.state.searchResults &&
                    this.state.searchResults.map((resultItem, i) => (
                        <article className="post-preview" key={i * 365}>
                            {/* {resultItem.pagemap.cse_image && (
                                <div className="img-holder">
                                    <picture>
                                        <img
                                            src={
                                                resultItem.pagemap.cse_image[0]
                                                    .src
                                            }
                                            alt=""
                                        />
                                    </picture>
                                </div>
                            )} */}
                            <div className="text-holder">
                                <h2>
                                    <a href={resultItem.link}>
                                        {resultItem.title}
                                    </a>
                                </h2>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: resultItem.htmlSnippet,
                                    }}
                                />
                            </div>
                        </article>
                    ))}
                <nav className="navigation pagination" role="navigation">
                    <div className="nav-links">
                        {this.state.nextPage && (
                            <a
                                className="next page-numbers"
                                href="#"
                                onClick={e => this._nextPage(e)}
                            >
                                Nächste Ergebnisse
                            </a>
                        )}
                        {this.state.prevPage && (
                            <a
                                className="prev page-numbers"
                                href="#"
                                onClick={e => this._prevPage(e)}
                            >
                                Vorherige Ergebnisse
                            </a>
                        )}
                    </div>
                </nav>
            </Fragment>
        );
    }
}

export default Search;
